<template>
    <b-card-code title="Payment History">
        <!-- input search -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                    />
                </div>
            </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
        >
            <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <div v-if="props.column.field === 'service_package_id'" class="text-nowrap">
                    <span class="text-nowrap">{{ props.row.service_package_details.title }}</span>
                </div>
                <div v-else-if="props.column.field === 'isp_id'" class="text-nowrap">
                    <span class="text-nowrap">{{ props.row.isp_info.company_name }}</span>
                </div>
                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'payment_status'">
                    <b-badge :variant="statusVariant(props.row.payment_status)">
                         {{ props.row.payment_status }}
                    </b-badge>
                </span>
                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                            v-model="pageLength"
                            :options="['10', '15', '20']"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import { SERVICES_PAYMENT_HISTORY } from "@core/services/api.js";
import $ from "jquery";

export default {
    components: {
        BCardCode,
        VueGoodTable,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: "Company Name",
                    field: "isp_id",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Company",
                    },
                },
                {
                    label: "TranID",
                    field: "tran_id",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search TranID",
                    },
                },
                {
                    label: "Amount",
                    field: "amount",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Amount",
                    },
                },
                {
                    label: "Package",
                    field: "service_package_id",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Package",
                    },
                },
                {
                    label: "Service Month",
                    field: "service_month",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Month",
                    },
                },
                {
                    label: "Payment Date",
                    field: "payment_date",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Payment Date",
                    },
                },
                {
                    label: "Payment Status",
                    field: "payment_status",
                    filterOptions: {
                        enabled: true,
                        placeholder: "Search Payment Status",
                    },
                }
            ],
            rows: [],
            searchTerm: "",
            isp_id: store.state.appConfig.isp_id,
        };
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                Current: "light-primary",
                success: "light-success",
                VALID: "light-success",
                failed: "light-danger",
                Resigned: "light-warning",
                Applied: "light-info",
                /* eslint-enable key-spacing */
            };

            return (status) => statusColor[status];
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
                return this.dir;
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false;
            return this.dir;
        },
    },
    mounted() {
        this.isp_id = $("#ispListId option:selected").val();
        let instance = this;
        $("#ispListId").change(function () {
            this.isp_id=  $(this).children("option:selected").val();
            instance.servicePaymentHistory(this.isp_id)
        });
        this.servicePaymentHistory(this.isp_id);
    },
    methods: {
        servicePaymentHistory(ispId) {
            this.$http
                .get(process.env.VUE_APP_BASEURL + SERVICES_PAYMENT_HISTORY + "?isp_id=" + ispId)
                .then((res) => {
                    this.rows = res.data.data.service_payment_history;
                });
        },
    },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
